<template>
  <v-list
    dense
  >
    <!-- Dashboard -->
    <v-list-item
      :to="{ name: 'dashboard' }"
      exact
    >
      <v-list-item-icon>
        <v-icon small>fas fa-tachometer-fast</v-icon>
      </v-list-item-icon>

      <v-list-item-title>Dashboard</v-list-item-title>
    </v-list-item>



    <!-- Relaties -->
    <v-list-group
      :value="false"
      no-action
    >

      <template v-slot:prependIcon>
        <v-icon small>fas fa-user-friends</v-icon>
      </template>

      <template v-slot:appendIcon>
        <v-icon small>fas fa-chevron-down</v-icon>
      </template>

      <template v-slot:activator>
        <v-list-item-title>Relaties</v-list-item-title>
      </template>

      <!-- Klanten -->
      <v-list-item
        :to="{ name: 'customers' }"
        exact
      >
        <v-list-item-title>Klanten</v-list-item-title>
      </v-list-item>

      <!-- Leveranciers -->
      <v-list-item
        :to="{ name: 'suppliers' }"
        exact
      >
        <v-list-item-title>Leveranciers</v-list-item-title>
      </v-list-item>

    </v-list-group>




    <!-- Verkoop -->
    <v-list-group
      :value="false"
      no-action
    >

      <template v-slot:appendIcon>
        <v-icon small>fas fa-chevron-down</v-icon>
      </template>

      <template v-slot:prependIcon>
        <v-icon small>fas fa-shopping-cart</v-icon>
      </template>

      <template v-slot:activator>
        <v-list-item-title>Verkoop</v-list-item-title>
      </template>

      <!-- Offertes -->
      <v-list-item
        :to="{ name: 'offers', params: { resetFilter: true } }"
        exact
      >
        <v-list-item-title>Offertes</v-list-item-title>
      </v-list-item>

      <!-- Artikelen -->
      <v-list-item
        :to="{ name: 'products' }"
        exact
      >
        <v-list-item-title>Artikelen</v-list-item-title>
      </v-list-item>

      <!-- Artikelgroepen -->
      <v-list-item
        :to="{ name: 'product-groups' }"
        exact
      >
        <v-list-item-title>Artikelgroepen</v-list-item-title>
      </v-list-item>

      <!-- Staffelprijzen -->
      <v-list-item
        :to="{ name: 'tier-prices' }"
        exact
      >
        <v-list-item-title>Staffelprijzen</v-list-item-title>
      </v-list-item>

      <!-- Systemen -->
      <v-list-item
        :to="{ name: 'systems' }"
        exact
      >
        <v-list-item-title>Systemen</v-list-item-title>
      </v-list-item>

      <!-- Factureren -->
      <v-list-item
        :to="{ name: 'billing' }"
        exact
      >
        <v-list-item-title>Factureren</v-list-item-title>
      </v-list-item>


    </v-list-group>




    <!-- Inkoop -->
    <v-list-group
      :value="false"
      no-action
    >

      <template v-slot:prependIcon>
        <v-icon small>fas fa-truck</v-icon>
      </template>

      <template v-slot:appendIcon>
        <v-icon small>fas fa-chevron-down</v-icon>
      </template>

      <template v-slot:activator>
        <v-list-item-title>Inkoop</v-list-item-title>
      </template>

      <!-- Artikelen in te kopen -->
      <v-list-item
        :to="{ name: 'buy' }"
        exact
      >
        <v-list-item-title>Artikelen in te kopen</v-list-item-title>
      </v-list-item>

      <!-- Bestelingen overzicht -->
      <v-list-item
        :to="{ name: 'purchasing-overview' }"
        exact
      >
        <v-list-item-title>Bestellingen overzicht</v-list-item-title>
      </v-list-item>

      <!-- Binnenkomst goederen -->
      <v-list-item
        :to="{ name: 'arrival-of-goods' }"
        exact
      >
        <v-list-item-title>Binnenkomst goederen</v-list-item-title>
      </v-list-item>

    </v-list-group>



    <!-- Montage -->
    <v-list-group
      :value="false"
      no-action
    >

      <template v-slot:prependIcon>
        <v-icon small>fas fa-wrench</v-icon>
      </template>

      <template v-slot:appendIcon>
        <v-icon small>fas fa-chevron-down</v-icon>
      </template>

      <template v-slot:activator>
        <v-list-item-title>Montage</v-list-item-title>
      </template>

      <!-- Inplannen -->
      <v-list-item
        :to="{ name: 'assembly-schedule' }"
        exact
      >
        <v-list-item-title>Inplannen</v-list-item-title>
      </v-list-item>

      <!-- Uitgevoerd melden -->
      <v-list-item
        :to="{ name: 'assembly-todo' }"
        exact
      >
        <v-list-item-title>Uitgevoerd melden</v-list-item-title>
      </v-list-item>

    </v-list-group>




    <!-- Instellingen -->
    <v-list-group
      :value="false"
      no-action
    >

      <template v-slot:prependIcon>
        <v-icon small>fas fa-cog</v-icon>
      </template>

      <template v-slot:appendIcon>
        <v-icon small>fas fa-chevron-down</v-icon>
      </template>

      <template v-slot:activator>
        <v-list-item-title>Instellingen</v-list-item-title>
      </template>

      <!-- Algemene gegevens -->
      <v-list-item
        :to="{ name: 'settings' }"
        exact
      >
        <v-list-item-title>Algemene gegevens</v-list-item-title>
      </v-list-item>

      <!-- Redenen van afwijzingen -->
      <v-list-item
        :to="{ name: 'reject-reasons' }"
        exact
      >
        <v-list-item-title>Redenen van afwijzingen</v-list-item-title>
      </v-list-item>

      <!-- Mail templates -->
      <v-list-item
        :to="{ name: 'mail-templates' }"
        exact
      >
        <v-list-item-title>Mail templates</v-list-item-title>
      </v-list-item>

      <!-- Gebruikers -->
      <v-list-item
        :to="{ name: 'users' }"
        exact
      >
        <v-list-item-title>Gebruikers</v-list-item-title>
      </v-list-item>

    </v-list-group>




  </v-list>
</template>

<script>
import { ref, watch } from '@vue/composition-api'


export default {
  name: 'Menu',
  props: {
    value: {},
  },

  setup(props, context) {

    const drawer = ref(props.value)

    watch(() => props.value, val => {
      drawer.value = val
    })

    watch(drawer, val => {
      context.emit('input', val)
    })

    return {
      drawer,
    }

  },
}
</script>
