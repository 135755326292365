<template>
 <v-app
    :style="{ 'background-color': $vuetify.theme.currentTheme.background }"
  >
    <v-navigation-drawer
      app
      v-if="isLoggedIn"
      v-model="drawer"
      clipped
      spermanent
    ><Menu v-model="drawer" /></v-navigation-drawer>

    <v-app-bar
      app
      v-if="isLoggedIn"
      flat
      color="title"
      clipped-left
      dark
    >
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>

      <v-img
        src="/logo-full.png"
        aspect-ratio="5"
        max-width="300"
        class="ml-16 clickable"
        @click="$route.name !== 'offers' ? $router.push({ name: 'offers', params: { resetFilter: true }}) : null"
      ></v-img>

      <v-spacer></v-spacer>

      <v-btn
        icon
        @click="logout()"
      ><v-icon
      >far fa-sign-out</v-icon></v-btn>

      <div
        class="mx-5"
      >{{ identity.first_name }}</div>

      <v-avatar
        color="title-grey"
        height="40"
        class="ml-2"
      >
        <v-icon
          color="title"
        >fas fa-user</v-icon>
      </v-avatar>

    </v-app-bar>

    <v-main
      v-if="isLoggedIn"
    >
      <v-container
        fluid
      >
        <router-view></router-view>
      </v-container>
    </v-main>

    <v-main
      v-else
    ><Login /></v-main>

    <div
      @click="overlayClick"
    >
      <v-overlay
        :opacity="0.5"
        :value="loader.state.isActive"
        z-index="999999"
      >
        <v-progress-circular
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-overlay>    
    </div>

  </v-app>
</template>

<script>
import { provide, inject, ref } from '@vue/composition-api'
import Login from '@/views/Login'
import Menu from '@/views/Menu'


export default {
  name: 'App',

  components: {
    Login,
    Menu,
  },

  setup() {

    const drawer = ref(true)
    const api = inject('api')
    const { isLoggedIn, logout, identity } = api.user

    const loader = api.utils.createLoader()
    provide('loader', loader)

    function overlayClick() {
      loader.clear()
    }

    return {
      identity,
      isLoggedIn,
      drawer,
      logout,
      loader,
      overlayClick,
    }

  }
};
</script>


<style>

.clickable {
  cursor: pointer;
}

</style>
